import React, { Component } from "react";

class PilotBanner extends Component {
  render() {
    return (

<div class="panel-group accordion" id="example-accordion" role="tablist" aria-multiselectable="true">

    <div class="panel panel-default">
        <div class="panel-heading" role="tab" id="headingOne">
            <h3 class="panel-title h4">

                <a class="collapsed" data-toggle="collapse" data-parent="#example-accordion" href="#collapseOne" aria-controls="collapseOne">Currently, CIRAS is only available to Region 2 Waters. Learn More.</a>
            </h3>
        </div>
        <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
            <div class="panel-body">
                Currently, CIRAS is being piloted in Region 2 (Alpine, Amador, Butte, Calaveras, Colusa, El Dorado, Glenn, Lake, Nevada, Placer, Plumas, Sierra, Sutter and Yuba counties, plus parts of Sacramento, San Joaquin and Yolo counties). Following the pilot phase, CDFW plans to open up CIRAS to all California counties.

            </div>
        </div>
    </div>

    </div>      

    );
  }
}

export default PilotBanner;
