import config from "../config";

const url = config.API;

export const fetchFromAPI = (proc) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      StoredProcedureName: proc,
    }),
  }).then((r) => r.json());
};

export const postResultsToAPI = (proc, data, headers) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: JSON.stringify({
      StoredProcedureName: proc,
      Payload: data,
    }),
  }).then((r) => r.json());
};

export const fetchFishImageFromAPI = (fishId) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      StoredProcedureName: "FishImage",
      Payload: { SpeciesID: fishId },
    }),
  })
    .then((r) => r.json())
    .then((response) => {
      return `data:image/png;base64,${response.result.Image[0].value}`;
    });
};

export const fetchPowerBIReport = (reportId) => {
  return fetch(config.POWER_BI_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ReportId: reportId,
    }),
  }).then((r) => r.json());
};
