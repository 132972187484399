import { createElement } from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from "survey-react-ui";
import MapComponent from "../components/MapComponent";

const CUSTOM_TYPE = "location-picker";

class QuestionLocationPickerModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  get locationPickerType() {
    return this.getPropertyValue("locationPickerType");
  }
  set locationPickerType(val) {
    this.setPropertyValue("locationPickerType", val);
  }

  get selectedLocation() {
    return this.getPropertyValue("selectedLocation");
  }
  set selectedLocation(val) {
    this.setPropertyValue("selectedLocation", val);
  }

  get geolocationPicker() {
    return this.getPropertyValue("geolocationPicker");
  }
  set geolocationPicker(val) {
    this.setPropertyValue("geolocationPicker", val);
  }

  get missingWater() {
    return this.getPropertyValue("missingWater");
  }
  set missingWater(val) {
    this.setPropertyValue("missingWater", val);
  }

  get basemapIndex() {
    return this.getPropertyValue("basemapIndex");
  }
  set basemapIndex(val) {
    this.setPropertyValue("basemapIndex", val);
  }
}

// Register `QuestionLocationPickerModel` as a model for the `location-picker` type
export function registerLocationPicker() {
  ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
    return new QuestionLocationPickerModel(name);
  });
}

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [
    {
      name: "selectedLocation",
    },
    {
      name: "geolocationPicker",
    },
    {
      name: "missingWater",
    },
    {
      name: "basemapIndex",
    },
  ],
  function () {
    return new QuestionLocationPickerModel();
  },
  "question"
);

class SurveyQuestionLocationPicker extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = {
      value: this.question.value,
    };
  }
  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  get selectedLocation() {
    return this.question.selectedLocation;
  }
  get geolocationPicker() {
    return this.question.geolocationPicker;
  }
  get missingWater() {
    return this.question.missingWater;
  }
  get basemapIndex() {
    return this.question.basemapIndex;
  }
  get type() {
    return this.question.geolocationPickerType;
  }

  handleLocationChange = (data) => {
    this.question.value = data;
  };

  setBasemapIndex = (index) => {
    this.question.basemapIndex = index;
  };

  renderLocationPicker() {
    return (
      <>
        {this.geolocationPicker && (
          <>
            {this.missingWater ? (
              <div style={{ textWrap: "wrap", fontSize: 16 }}>
                Please select the approximate location you fished by placing a
                pin on the map. If you are unable to find the water on the map,
                place the pin near the location where you fished. After
                identifying your water, please take the survey to let us know
                what you caught.
              </div>
            ) : (
              <div style={{ textWrap: "wrap", fontSize: 16 }}>
                Tap the map to drop a pin in the approximate area that you were
                fishing. You can change it at any time by tapping another spot
                on the map. <b>Pin location WILL NOT be shared on CIRAS.</b> If
                fishing multiple locations, please drop a pin in the centralized
                point within that area.
              </div>
            )}
          </>
        )}
        {!this.geolocationPicker &&
          this.selectedLocation &&
          (!this.question.value ||
            Object.keys(this.question.value).length === 0) && (
            <div style={{ textWrap: "wrap", fontSize: 16 }}>
              Please select one of the bodies of waters on the map to continue.
            </div>
          )}
        <MapComponent
          selectedLocation={this.selectedLocation}
          onChange={this.handleLocationChange}
          setBasemapIndex={this.setBasemapIndex}
          basemapIndex={this.basemapIndex}
          missingWater={this.missingWater}
          geolocationPicker={this.geolocationPicker}
        />
      </>
    );
  }

  renderElement() {
    return <div style={this.style}>{this.renderLocationPicker(this.type)}</div>;
  }
}

ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return createElement(SurveyQuestionLocationPicker, props);
});
