import React, { Component } from "react";
import logo from "../images/Ca-Gov-Logo-Gold.svg";
import "./StateTemplateHeader.css";
import headerLogo from "../images/CIRAS-Logo_Final_for-LeftCornerWeb-ver2-03.jpg";
import { Link } from "react-router-dom";
import { ReactComponent as ReadyToFishIcon } from "../images/ReadyToFishIconHeader.svg";
import SvgIcon from "@mui/material/SvgIcon";
import config from "../config.js";
class StateTemplateHeader extends Component {

  constructor() {
    super();

    this.state = {
      isEnvWarningDisplayed : (config.STAGE !== "PROD" ),
      isTestEnviroment : (config.STAGE === "TEST" )
    };
  }  


  render() {
    return (
      <header role="banner" id="header" className="global-header">

        <div id="skip-to-content">
          <a href="#main-content">Skip to Main Content</a>
        </div>

        {
          this.state.isEnvWarningDisplayed &&

          <div className="alert alert-warning alert-dismissible alert-banner" role="alert">
            <div className="container">

                <span className="alert-level">
                  <span className="ca-gov-icon-important" aria-hidden="true"></span>
                  Warning! This is the {config.STAGE_NAME} environment! 
                </span>
                <span className="alert-text">This is not a production system and is for testing purposes only. All data entered may be lost at any time.</span> 
            </div>
          </div>
      }

        <div className="utility-header">
          <div className="container">
            <div className="group flex-row">
              <div className="social-media-links">
                <div className="header-cagov-logo">
                  <a href="https://ca.gov">
                    <span className="sr-only">CA.gov</span>
                    <img
                      src={logo}
                      className="pos-rel"
                      alt=""
                      aria-hidden="true"
                    />
                  </a>
                </div>
                <div className="settings-links">
                  <a href="https://wildlife.ca.gov">
                    <span className="ca-gov-icon-home"></span> CDFW Home
                  </a>
                </div>

              </div>
              <div className="settings-links">
                <button
                  type="button"
                  className="btn btn-xs btn-primary"
                  data-toggle="collapse"
                  data-target="#languageBar"
                  aria-expanded="false"
                  aria-controls="languageBar"
                  title="Google Translate"
                >
                  <span className="ca-gov-icon-globe" aria-hidden="true"></span>{" "}
                  Translate
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="language-settings section section-default collapse collapsed"
          role="alert"
          id="languageBar"
        >
          <div className="container p-y">
            <button
              type="button"
              className="close"
              data-toggle="collapse"
              data-target="#languageBar"
              aria-expanded="false"
              aria-controls="searchBar"
              aria-label="Close"
            >
              <span
                className="ca-gov-icon-close-mark"
                aria-hidden="true"
              ></span>
            </button>

            <div id="google_translate_element"></div>
          </div>
        </div>

        <div className="branding">
          <div className="header-organization-banner">
            <Link to="/">
              <img
                src={headerLogo}
                alt="California Inland Recreational Angler Survey"
              />
            </Link>
          </div>
        </div>

        <div className="mobile-controls">
          <span className="mobile-control-group mobile-header-icons"></span>
          <div className="mobile-control-group main-nav-icons">
            <button
              id="nav-icon3"
              className="mobile-control toggle-menu"
              aria-expanded="false"
              aria-controls="navigation"
              data-toggle="collapse"
              data-target="#navigation"
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span className="sr-only">Menu</span>
            </button>
          </div>
        </div>

        <div className="navigation-search">
          <nav
            id="navigation"
            className="main-navigation dropdown hidden-print"
          >
            <ul id="nav_list" className="top-level-nav">
              <li className="nav-item menu-item menu-item-type-post_type menu-item-object-page">
                <Link className="first-level-link" to="survey">
                  <span
                    className="ca-gov-icon-online-services"
                    aria-hidden="true"
                  ></span>{" "}
                  <span className="link-title">Enter Survey Data</span>
                </Link>
              </li>
              <li className="nav-item menu-item menu-item-type-post_type menu-item-object-page">
                <Link className="first-level-link" to="dashboard">
                  <span aria-hidden="true">
                    <SvgIcon
                      className="ready-to-fish-svg"
                      component={ReadyToFishIcon}
                      inheritViewBox
                    />
                  </span>
                  <span className="link-title">Ready to Fish?</span>
                </Link>
              </li>



              {
              this.state.isTestEnviroment &&

              
              
                  
              <li className="nav-item menu-item menu-item-type-custom menu-item-object-custom">
                <a href="https://forms.office.com/g/gcwuX7WcXE" className="first-level-link" target="_blank">
                  <span className="ca-gov-icon-book" />
                  <span className="link-title">Feedback Form</span>
                </a>
              </li>


              }

              {
              !this.state.isTestEnviroment &&





              <li className="nav-item menu-item menu-item-type-custom menu-item-object-custom">
                <a href="/Resources" className="first-level-link">
                  <span className="ca-gov-icon-book" />
                  <span className="link-title">Resources</span>
                </a>
                <div className="empty sub-nav">
                  <div>
                    <ul className="second-level-nav">
                      {/* <li className="unit1 menu-item menu-item-type-custom menu-item-object-custom">
                        <a
                          href="https://wildlife.ca.gov/Fishing/Inland/CIRAS/User-Guide"
                          className="second-level-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CIRAS User Guide
                        </a>
                      </li>
                      <li className="unit1 menu-item menu-item-type-custom menu-item-object-custom">
                        <a
                          href="https://wildlife.ca.gov/Fishing/Inland/CIRAS/Tutorials"
                          className="second-level-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CIRAS Tutorials
                        </a>
                      </li>
                      <li className="unit1 menu-item menu-item-type-custom menu-item-object-custom">
                        <a
                          href="https://wildlife.ca.gov/Fishing/Inland/CIRAS/FAQ"
                          className="second-level-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          FAQs
                        </a>
                      </li> */}
                      <li className="unit1 menu-item menu-item-type-custom menu-item-object-custom">
                        <a
                          href="https://wildlife.ca.gov/Fishing/Inland/CIRAS/Contact"
                          className="second-level-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Contact Us/Feedback
                        </a>
                      </li>
                      {/* <li className="unit1 menu-item menu-item-type-custom menu-item-object-custom">
                        <a
                          href="https://wildlife.ca.gov/Fishing/Inland/CIRAS/News"
                          className="second-level-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CIRAS News
                        </a>
                      </li> */}
                      <li className="unit1 menu-item menu-item-type-custom menu-item-object-custom">
                        <a
                          href="https://wildlife.ca.gov/Fishing/Inland"
                          className="second-level-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Fishing Regulations
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              }

            </ul>
          </nav>
          <div id="head-search" >

              {/*  */}

            </div>
        </div>
        <div className="header-decoration" >



          </div>
      </header>
    );
  }
}

export default StateTemplateHeader;
