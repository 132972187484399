export default function LocationQuestions(props) {
  let locationQuestionsJson = {};

  if (props.data) {
    locationQuestionsJson = {
      pages: [
        {
          name: "Where",
          title: "Where did you fish?",
          elements: [
            {
              name: "location-dropdown",
              title: "Location",
              type: "dropdown",
              isRequired: true,
              choicesLazyLoadEnabled: true,
              choicesLazyLoadPageSize: 40,
            },
            {
              type: "location-picker",
              visibleIf: "{location-dropdown} notempty",
              name: "location-map",
              titleLocation: "hidden",
              basemapIndex: props.basemapIndex,
              isRequired: true,
            },
          ],
        },
      ],
      showQuestionNumbers: false,
    };
  }

  return locationQuestionsJson;
}
