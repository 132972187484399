import { createElement } from "react";
import { ElementFactory, Serializer, QuestionCheckboxBase } from "survey-core";
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from "survey-react-ui";
import Typography from "@mui/material/Typography";
import Carousel from "react-material-ui-carousel";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import config from "../config";

const CUSTOM_TYPE = "fish-carousel";

class QuestionFishCarouselModel extends QuestionCheckboxBase {
  getType() {
    return CUSTOM_TYPE;
  }
  get fishCarouselType() {
    return this.getPropertyValue("fishCarouselType");
  }
  set fishCarouselType(val) {
    this.setPropertyValue("fishCarouselType", val);
  }

  get fishImageMap() {
    return this.getPropertyValue("fishImageMap");
  }
  set fishImageMap(val) {
    this.setPropertyValue("fishImageMap", val);
  }
}

// Register `QuestionFishCarouselModel` as a model for the `fish-carousel` type
export function registerFishCarousel() {
  ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
    return new QuestionFishCarouselModel(name);
  });
}

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [
    {
      name: "fishImageMap",
    },
  ],
  function () {
    return new QuestionFishCarouselModel();
  },
  "checkboxbase"
);

class SurveyQuestionFishCarousel extends SurveyQuestionElementBase {
  get question() {
    return this.questionBase;
  }

  get fishImageMap() {
    return this.question.fishImageMap;
  }

  renderElement() {
    return (
      this.getItems().length > 0 && (
        <Carousel
          animation="fade"
          navButtonsAlwaysVisible
          autoPlay={false}
          navButtonsProps={{
            style: {
              backgroundColor: "transparent",
            },
          }}
          NextIcon={
            <ArrowRightIcon
              sx={{
                fontSize: "4rem",
                color: () => "#045070",
              }}
            />
          }
          PrevIcon={
            <ArrowLeftIcon
              sx={{
                fontSize: "4rem",
                color: () => "#045070",
              }}
            />
          }
        >
          {this.getItems()}
        </Carousel>
      )
    );
  }

  getItems() {
    var items = [];
    for (var i = 0; i < this.question.visibleChoices.length; i++) {
      var item = this.question.visibleChoices[i];
      var key = "item" + i;

      items.push(this.renderItem(key, item));
    }
    return items;
  }

  renderItem(key, item) {
    const fishImageBase64 = this.fishImageMap.has(item.value)
      ? this.fishImageMap.get(item.value)
      : this.fishImageMap.get(config.DEFAULT_FISH_IMAGE_ID);
    const renderedItem = (
      <div className="trending-species-fish" key={key}>
        <img alt={item.text} src={fishImageBase64} />
        <Typography
          component="span"
          variant="h3"
          sx={{
            fontSize: "1.5rem",
          }}
        >
          {item.text}
        </Typography>
      </div>
    );

    return renderedItem;
  }
}

ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return createElement(SurveyQuestionFishCarousel, props);
});
