export default function SurveyQuestions(props) {
  let surveyQuestionsJson = {};

  if (props.data) {
    surveyQuestionsJson = {
      pages: [
        {
          name: "Where",
          title: "Where did you fish?",
          elements: [
            {
              name: "uuid",
              type: "expression",
              defaultValue: props.uuid,
              visible: false,
            },
            {
              name: "DeviceUsed",
              type: "expression",
              defaultValue: props.deviceUsed,
              visible: false,
            },
            {
              name: "SurveyDuration",
              type: "expression",
              visible: false,
            },
            {
              name: "LocationName",
              title: "Location",
              type: "expression",
            },
            {
              name: "MissingCounty",
              type: "expression",
              visible: false,
            },
            {
              name: "MissingDescription",
              type: "expression",
              visible: false,
            },
            {
              name: "PhoneNumber",
              type: "expression",
              visible: false,
            },
            {
              name: "AnglerName",
              type: "expression",
              visible: false,
            },
            {
              name: "AnglerEmail",
              type: "expression",
              visible: false,
            },
            {
              name: "location",
              type: "expression",
              visible: false,
            },
            {
              type: "location-picker",
              name: "geolocation",
              geolocationPicker: true,
              titleLocation: "hidden",
              isRequired: true,
              requiredErrorText: "Please drop pin on your fishing location.",
            },
          ],
        },
        {
          name: "When",
          title: "When did you fish?",
          elements: [
            {
              name: "SurveyDate",
              type: "text",
              title: "Date",
              inputType: "date",
              defaultValueExpression: "today()",
              maxValueExpression: "today()",
              isRequired: true,
            },
            {
              name: "SurveyStart",
              type: "text",
              title: "Start of fishing day",
              inputType: "time",
              isRequired: true,
            },
            {
              name: "SurveyHours",
              type: "text",
              title: "# of hours fished",
              inputType: "number",
              min: 0.1,
              max: 24,
              isRequired: true,
            },
          ],
        },
        {
          name: "WhatFish",
          title: "What did you fish for?",
          elements: [
            {
              type: "boolean",
              name: "TargetFish",
              title: "Were you fishing for specific species?",
              valueTrue: "Yes",
              valueFalse: "No",
              isRequired: true,
            },
            {
              type: "tagbox",
              visibleIf: "{TargetFish} = Yes",
              choices: props.data.fish,
              name: "WhatSpecies",
              title: "Fish species",
              isRequired: true,
            },
            {
              type: "fish-carousel",
              visibleIf: "{TargetFish} = Yes",
              name: "FishCarousel",
              titleLocation: "hidden",
              fishImageMap: props.fishImageMap,
              choicesFromQuestion: "WhatSpecies",
              choicesFromQuestionMode: "selected",
            },
          ],
        },
        {
          name: "WhatCatch",
          title: "What did you catch?",
          description:
            "Please record the number of fish kept and released for each fish size and species caught. Click the info button for more details.",
          elements: [
            {
              type: "boolean",
              name: "CatchFish",
              title: "Did you catch fish?",
              valueTrue: "Yes",
              valueFalse: "No",
              isRequired: true,
            },
            {
              type: "matrixdynamic",
              visibleIf: "{CatchFish} = Yes",
              name: "fish-table",
              titleLocation: "hidden",
              allowRemoveRows: false,
              columns: [
                {
                  title: "Fish Species",
                  name: "FishFinder",
                  cellType: "fishfinder",
                  isRequired: true,
                },
                {
                  name: "FishSize",
                  title: "Size (inch)",
                  cellType: "text",
                  inputType: "number",
                  isRequired: true,
                  validators: [
                    {
                      type: "regex",
                      text: "Please only enter only whole numbers less than 100.",
                      regex: "^([0-9]|[1-9][0-9])$",
                    },
                  ],
                },
                {
                  name: "FishKept",
                  title: "# Kept",
                  cellType: "text",
                  inputType: "number",
                  validators: [
                    {
                      type: "regex",
                      text: "Please only enter only whole numbers less than 100.",
                      regex: "^([0-9]|[1-9][0-9])$",
                    },
                  ],
                },
                {
                  name: "FishReleased",
                  title: "# Released",
                  cellType: "text",
                  inputType: "number",
                  validators: [
                    {
                      type: "regex",
                      text: "Please only enter only whole numbers less than 100.",
                      regex: "^([0-9]|[1-9][0-9])$",
                    },
                  ],
                },
              ],
              rowCount: 1,
              addRowText: "Add to Catch List",
            },
            {
              type: "matrixDynamic",
              visibleIf: "{CatchFish} = Yes && {fish-caught.length} > 0",
              name: "fish-caught",
              titleLocation: "hidden",
              allowAddRows: false,
              columns: [
                {
                  name: "displayValue",
                  title: "Fish Species",
                  cellType: "expression",
                },
                {
                  name: "value",
                  cellType: "expression",
                  visible: false,
                },
                {
                  name: "FishSize",
                  title: "Size (inch)",
                  cellType: "expression",
                },
                {
                  name: "FishKept",
                  title: "# Kept",
                  cellType: "expression",
                },
                {
                  name: "FishReleased",
                  title: "# Released",
                  cellType: "expression",
                },
                {
                  name: "total",
                  title: "Total Fish",
                  cellType: "expression",
                  expression: "{row.FishKept} + {row.FishReleased}",
                  totalType: "sum",
                  visible: false,
                },
                {
                  name: "total-kept",
                  title: "Total Kept",
                  cellType: "expression",
                  expression: "{row.FishKept}",
                  totalType: "sum",
                  visible: false,
                },
                {
                  name: "total-released",
                  title: "Total Released",
                  cellType: "expression",
                  expression: "{row.FishReleased}",
                  totalType: "sum",
                  visible: false,
                },
              ],
              rowCount: 0,
            },
            {
              name: "totalFish",
              visibleIf: "{CatchFish} = Yes && {fish-caught-total.total} > 0",
              minWidth: "200px",
              type: "expression",
              title: "Total # Fish: ",
              expression: "{fish-caught-total.total}",
              startWithNewLine: true,
            },
            {
              name: "totalKept",
              visibleIf: "{CatchFish} = Yes && {fish-caught-total.total} > 0",
              minWidth: "200px",
              type: "expression",
              title: "Total # Kept: ",
              expression: "{fish-caught-total.total-kept}",
              startWithNewLine: false,
            },
            {
              name: "totalReleased",
              visibleIf: "{CatchFish} = Yes && {fish-caught-total.total} > 0",
              minWidth: "200px",
              type: "expression",
              title: "Total # Released: ",
              expression: "{fish-caught-total.total-released}",
              startWithNewLine: false,
            },
          ],
        },
        {
          name: "WhatGear",
          title: "What did you use?",
          elements: [
            {
              type: "checkbox",
              name: "Gear",
              title: "Gear Used",
              choices: ["Bait", "Fly", "Lure"],
              isRequired: true,
              colCount: 4,
              showNoneItem: false,
              showOtherItem: true,
              showSelectAllItem: false,
              separateSpecialChoices: false,
            },
            {
              name: "GearOther",
              type: "expression",
              visible: false,
            },
            {
              type: "boolean",
              name: "SecondRod",
              label: "Used Second Rod",
              titleLocation: "hidden",
              valueTrue: "Yes",
              valueFalse: "No",
              defaultValue: "No",
              renderAs: "checkbox",
            },
            {
              name: "FishedFrom",
              type: "dropdown",
              choices: props.data.FishedFrom,
              title: "Where did you fish from?",
              isRequired: true,
            },
          ],
        },
        {
          name: "Satisfaction",
          title: "How satisfied are you?",
          elements: [
            {
              type: "matrix",
              name: "quality",
              titleLocation: "hidden",
              columns: [
                {
                  value: 5,
                  text: "Very Satisfied",
                },
                {
                  value: 4,
                  text: "Somewhat Satisfied",
                },
                {
                  value: 3,
                  text: "Neutral",
                },
                {
                  value: 2,
                  text: "Somewhat Unsatisfied",
                },
                {
                  value: 1,
                  text: "Very Unsatisfied",
                },

              ],
              rows: [
                {
                  value: "Overall",
                  text: "Overall Angling Experience",
                },
                {
                  value: "Size",
                  text: "Size of Fish",
                },
                {
                  value: "Number",
                  text: "Number of Fish",
                },
              ],
              alternateRows: true,
              isAllRowRequired: true,
            },
            {
              type: "comment",
              name: "Comments",
              title: "Additional comments about this fishing trip",
              maxLength: 500,
            },
          ],
        },
        {
          name: "About",
          title: "About you",
          elements: [
            {
              name: "Zip",
              type: "text",
              title: "Home Zip Code",
              inputType: "text",
              autocomplete: "postal-code",
              isRequired: true,
              maxLength: 5,
            },
          ],
        },
      ],
      showQuestionNumbers: false,
      allowResizeComment: false,
      autoGrowComment: true,
      questionsOnPageMode: "singlePage",
    };
  }
  return surveyQuestionsJson;
}
