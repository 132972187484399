import StateTemplateHeader from "./components/StateTemplateHeader";
import StateTemplateFooter from "./components/StateTemplateFooter";
import CDFWFooter from "./components/CDFWFooter";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/main/HomePage";
import SurveyPage from "./components/main/SurveyPage";
import DashboardPage from "./components/main/DashboardPage";

import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <StateTemplateHeader />
        <div id="main-content" className="main-content">
          <main className="main-primary">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="survey" element={<SurveyPage />} />
              <Route path="dashboard" element={<DashboardPage />} />
            </Routes>
          </main>
        </div>
        <CDFWFooter />
        <StateTemplateFooter />
      </div>
    </Router>
  );
}

export default App;
