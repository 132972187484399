import React, { Component } from "react";
import "./CDFWFooter.css";

class Footer extends Component {
  render() {
    return (
      <div id="ciras-footer" className="section section-standout">
        <div className="container">
          <div className="cdfw_footer">
            <p className="bold">
              <a href="https://wildlife.ca.gov/">
                California Department of Fish and Wildlife
              </a>
            </p>
            <p className="bold">
              <a href="https://wildlife.ca.gov/Organization/FB">
                Fisheries Branch
              </a>
            </p>
            <p>Branch Chief: Jay Rowan</p>
            <p>
              1010 Riverside Parkway
              <br />
              West Sacramento, CA 95605
            </p>
            <p>
              <a className="underline" href="mailto:CIRAS@wildlife.ca.gov">
                CIRAS@wildlife.ca.gov
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
