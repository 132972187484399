export default function MissingWaterQuestions(props) {
  let missingWaterQuestionsJson = {};

  if (props.data) {
    missingWaterQuestionsJson = {
      pages: [
        {
          name: "MissingWater",
          title: "Can't find your water?",
          elements: [
            {
              name: "mw-water-name",
              type: "text",
              title: "Name of Body of Water looking for",
              inputType: "text",
              isRequired: true,
            },
            {
              name: "mw-county-dropdown",
              title: "County Body of Water found in",
              type: "dropdown",
              choices: props.data.Counties,
            },
            {
              name: "mw-water-description",
              type: "comment",
              title: "Location Description",
              inputType: "text",
              maxLength: 500,
            },
            {
              name: "missing-waters-map",
              type: "location-picker",
              geolocationPicker: true,
              missingWater: true,
              titleLocation: "hidden",
              isRequired: true,
              requiredErrorText: "Please drop pin on your fishing location.",
            },
            {
              name: "mw-name",
              type: "text",
              title: "Name (Optional)",
              inputType: "text",
              autocomplete: "name",
              placeholder: "Full Name",
            },
            {
              name: "mw-phone",
              type: "text",
              title: "Phone Number (Optional)",
              inputType: "tel",
              autocomplete: "tel",
              placeholder: "Phone Number",
            },
            {
              name: "mw-email",
              type: "text",
              title: "Email (Optional)",
              inputType: "email",
              autocomplete: "email",
              placeholder: "Email",
            },
          ],
        },
      ],
      showQuestionNumbers: false,
    };
  }

  return missingWaterQuestionsJson;
}
