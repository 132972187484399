import AdiposeFinImage from "../images/AdiposeFinExample.jpg";

export default function PostSurveyQuestions(props) {
  let postSurveyQuestions = {};

  if (props.data) {
    postSurveyQuestions = {
      pages: [
        {
          name: "fish-fin-clip",
          title: "Fish Fin Clip",
          description:
            "Submitting data to CIRAS is not a substitute for species specific report cards (steelhead, Chinook, Sturgeon).",
          elements: [
            {
              type: "image",
              name: "surveyJS-integration-illustration",
              imageLink: AdiposeFinImage,
              altText: "SurveyJS component integration",
              visible: props.data.fin_tagged !== null,
            },
            {
              type: "matrixdropdown",
              name: "fin-clip",
              title: "Did any of your fish have a clipped fin?",
              visible: props.data.adipose !== null,
              columns: [
                {
                  name: "Size",
                  title: "Size",
                  cellType: "expression",
                  expression: "getFishProperty('size')",
                },
                {
                  name: "Action",
                  title: "Action",
                  cellType: "expression",
                  expression: "getFishProperty('action')",
                },
                {
                  name: "Adipose",
                  cellType: "radiogroup",
                  showInMultipleColumns: true,
                  choices: ["Adipose", "Other", "No", "Unknown"],
                  defaultValue: "Unknown",
                },
              ],
              rows: props.data.adipose,
            },
          ],
        },
        {
          name: "fish-tagged",
          title: "Fish Tagged",
          visible: props.data.fin_tagged !== null,
          elements: [
            {
              type: "matrixdropdown",
              name: "fin-tagged",
              title: "Were any of your fish tagged?",
              columns: [
                {
                  name: "Size",
                  title: "Size",
                  cellType: "expression",
                  expression: "getFishProperty('size')",
                },
                {
                  name: "Action",
                  title: "Action",
                  cellType: "expression",
                  expression: "getFishProperty('action')",
                },
                {
                  name: "Tagged",
                  cellType: "radiogroup",
                  showInMultipleColumns: true,
                  choices: ["Yes", "No", "Unknown"],
                  defaultValue: "Unknown",
                },
                {
                  name: "Tag",
                  title: "Tag ID",
                  cellType: "text",
                  enableIf: "{row.Tagged} = Yes",
                },
              ],
              rows: props.data.fin_tagged,
            },
          ],
        },
        {
          name: "fishing_in_the_city",
          title: "Fishing in the City Adaptive Questions",
          visible: props.data.fishing_in_the_city[0].value === true,
          elements: [
            {
              name: "Cooking",
              type: "radiogroup",
              title: "Did you or do you plan to cook or eat your catch?",
              colCount: 3,
              choices: props.data.Cooking,
            },
            {
              name: "Condition",
              type: "radiogroup",
              title: "What was the condition of your fishing location?",
              colCount: 3,
              choices: props.data.Condition,
            },
            {
              name: "CompASB",
              type: "radiogroup",
              title:
                "Have you completed a CDFW Angler Survey Box (ASB) survey before?",
              colCount: 3,
              choices: props.data.CompASB,
            },
            {
              name: "Travel",
              type: "radiogroup",
              title: "How did you travel on this trip?",
              colCount: 3,
              choices: props.data.Travel,
              showOtherItem: true,
            },
            {
              name: "TravelOther",
              type: "expression",
              visible: false,
            },
          ],
        },
        {
          name: "About",
          title: "About you",
          description:
            "Please enter optional demographic information below. Demographic data will be kept confidential.",
          elements: [
            {
              name: "surveyID",
              type: "expression",
              defaultValue: props.data.surveyID,
              visible: false,
            },
            {
              name: "goid",
              type: "text",
              title: "GOID",
              inputType: "number",
              validators: [
                {
                  type: "regex",
                  text: "Please only enter numbers (10 digits).",
                  regex: "^[0-9]{10}$",
                },
              ],
            },
            {
              name: "Age",
              type: "text",
              title: "Age",
              min: "1",
              max: "120",
              inputType: "number",
            },
            {
              name: "Gender",
              type: "dropdown",
              title: "Gender",
              choices: props.data.Gender,
              showOtherItem: true,
            },
            {
              name: "GenderOther",
              type: "expression",
              visible: false,
            },
            {
              name: "Race",
              type: "dropdown",
              title: "Race/Ethnicity",
              choices: props.data.Race,
              showOtherItem: true,
              otherText: "Multiple / Other",
            },
            {
              name: "RaceOther",
              type: "expression",
              visible: false,
            },
            {
              name: "Language",
              type: "dropdown",
              title: "Primary language spoken at home",
              choices: props.data.Language,
              showOtherItem: true,
            },
            {
              name: "LanguageOther",
              type: "expression",
              visible: false,
            },
            {
              name: "AccessImprove",
              type: "dropdown",
              title:
                "Which accessibility improvement would you like to see CDFW direct resources towards? (please select your top priority)",
              choices: props.data.AccessImprove,
            },
          ],
        },
      ],
      showQuestionNumbers: false,
      questionsOnPageMode: "singlePage",
      completedHtml: "<h3>Thank you for your submission</h3>",
    };
  }
  return postSurveyQuestions;
}
