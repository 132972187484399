import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

const override = {
  textAlign: "center",
  margin: "50px",
};

function Loader() {
  return <BeatLoader cssOverride={override} color={"#045070"} />;
}
export default Loader;
