import React, { Component } from "react";

class StateTemplateFooter extends Component {
  render() {
    return (
      <footer id="footer" className="global-footer hidden-print">
        <div className="container footer-menu">
          <div className="row">
            <div className="col-md-3">
              <ul className="footer-links">
                <li>
                  <a href="#skip-to-content">Back to Top</a>
                </li>
              </ul>
            </div>
            {/* <div className="col-md-9">
              <ul className="socialsharer-container">
                <li>
                  <a
                    href="https://wildlife.ca.gov/Connect"
                    title="Facebook Pages"
                  >
                    <span
                      className="ca-gov-icon-facebook"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Facebook</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://wildlife.ca.gov/Connect"
                    title="Twitter Feeds"
                  >
                    <span
                      className="ca-gov-icon-twitter"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Twitter</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/CaliforniaDFG"
                    title="YouTube Channel"
                  >
                    <span
                      className="ca-gov-icon-youtube"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">YouTube</span>
                  </a>
                </li>
                <li>
                  <a href="https://wildlife.ca.gov/Connect" title="Blog Sites">
                    <span
                      className="ca-gov-icon-loud-speaker"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Blogs</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.flickr.com/photos/CaliforniaDFG"
                    title="Photos"
                  >
                    <span
                      className="ca-gov-icon-flickr"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Flickr</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.dfg.ca.gov/mobile/" title="Mobile Apps">
                    <span
                      className="ca-gov-icon-smartphone"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Mobile Apps</span>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            Copyright &copy; {new Date().getFullYear()} State of California
          </div>
        </div>
      </footer>
    );
  }
}

export default StateTemplateFooter;
