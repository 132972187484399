// MapComponent.js
import React, { useRef, useEffect } from "react";
import MapView from "@arcgis/core/views/MapView.js";
import Map from "@arcgis/core/Map.js";
import Graphic from "@arcgis/core/Graphic.js";
import "@arcgis/core/assets/esri/themes/light/main.css";
import WebStyleSymbol from "@arcgis/core/symbols/WebStyleSymbol.js";
import Basemap from "@arcgis/core/Basemap.js";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery.js";
import Locate from "@arcgis/core/widgets/Locate.js";
import Expand from "@arcgis/core/widgets/Expand.js";

const MapComponent = (props) => {
  const mapRef = useRef(null);

  const selectedLocation = props.selectedLocation;
  const geolocationPicker = props.geolocationPicker;
  const onChange = props.onChange;
  const basemapIndex = props.basemapIndex;
  const setBasemapIndex = props.setBasemapIndex;
  const missingWater = props.missingWater;

  useEffect(() => {
    if (!mapRef.current || !selectedLocation || selectedLocation.length === 0)
      return;

    console.log("selectedLocation", selectedLocation);

    var basemaps = [
      Basemap.fromId("topo-vector"),
      Basemap.fromId("hybrid"),
      Basemap.fromId("streets-navigation-vector"),
    ];

    let curBasemap;
    switch (basemapIndex) {
      case 0:
        curBasemap = "topo-vector";
        break;
      case 1:
        curBasemap = "hybrid";
        break;
      case 2:
        curBasemap = "streets-navigation-vector";
        break;
      default: // default to topo-vector
        curBasemap = "topo-vector";
    }

    // initialize the map with topo-vector basemap if not specified
    const map = new Map({
      basemap: curBasemap,
    });

    // Define marker colors
    var defaultMarkerSymbol = {
      type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
      color: "orange",
    };

    var selectMarkerSymbol = {
      type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
      color: "lime",
    };

    const view = new MapView({
      container: mapRef.current,
      map: map,
      center: [selectedLocation[0].longitude, selectedLocation[0].latitude],
      zoom: selectedLocation[0].zoom ? selectedLocation[0].zoom : 13,
      // spatialReference: viewSpatialReference,
    });

    if (missingWater) {
      let locateWidget = new Locate({
        view: view, // Attaches the Locate button to the view
        graphic: new Graphic({
          symbol: { type: "simple-marker" }, // overwrites the default symbol used for the
          // graphic placed at the location of the user when found
        }),
      });
      view.ui.add(locateWidget, "bottom-right");
    }

    const basemapGallary = new BasemapGallery({
      view: view,
      source: basemaps, // autocasts to LocalBasemapsSource
    });

    const bgExpand = new Expand({
      view,
      content: basemapGallary,
      expandIcon: "layers",
      expandTooltip: "Change Map Imagery",
    });
    view.ui.add(bgExpand, "top-right");

    map.watch("basemap", (newBasemap) => {
      setBasemapIndex(basemaps.indexOf(newBasemap));
      bgExpand.toggle();
    });

    if (geolocationPicker) {
      if (!missingWater) {
        createGraphic(selectedLocation[0], true);
      }

      view.on("click", function (event) {
        createPointMarker(event.mapPoint.latitude, event.mapPoint.longitude);
        onChange({
          latitude: event.mapPoint.latitude,
          longitude: event.mapPoint.longitude,
        });
      });

      function createPointMarker(lat, long) {
        // First create a point geometry
        var point = {
          type: "point", // autocasts as new Point()
          latitude: lat,
          longitude: long,
        };

        // Create a symbol for drawing the point
        var markerSymbol = new WebStyleSymbol({
          name: "tear-pin-1",
          styleName: "Esri2DPointSymbolsStyle",
        });

        // Create a graphic and add the geometry and symbol to it
        var pointGraphic = new Graphic({
          geometry: point,
          symbol: markerSymbol,
        });

        // Clear the existing graphics
        view.graphics.removeAll();

        // Add the graphics to the view's graphics layer
        view.graphics.add(pointGraphic);

        if (!missingWater) {
          createGraphic(selectedLocation[0], true);
        }
      }
    } else if (selectedLocation) {
      if (selectedLocation.length === 1) {
        const loc = selectedLocation[0];
        createGraphic(selectedLocation[0], true);

        onChange({
          latitude: loc.latitude,
          longitude: loc.longitude,
          text: loc.text,
          value: loc.value,
        });
      } else {
        onChange({}); // clear any selected value
        for (const location of selectedLocation) {
          createGraphic(location, false);
        }

        // get screen point from view's click event
        view.on("click", (event) => {
          // Search for all features only on included layers at the clicked location
          view.hitTest(event).then((response) => {
            // if graphics are returned, do something with results
            if (response.results.length && response.results[0].graphic.symbol) {
              const graphic = response.results[0].graphic;
              // Un-highlight all other graphics
              view.graphics.forEach((g) => {
                if (g !== graphic) {
                  g.symbol = defaultMarkerSymbol;
                }
              });

              // Highlight the clicked graphic
              graphic.symbol = selectMarkerSymbol;

              view.graphics.reorder(graphic, 9999); // move to top

              onChange({
                latitude: graphic.geometry.y,
                longitude: graphic.geometry.x,
                text: graphic.attributes.text,
                value: graphic.attributes.value,
              });
            }
          });
        });
      }

      view
        .when(function () {
          // MapView is now ready for display and can be used. Here we will
          // use goTo to view a particular location at a given zoom level and center
          view.goTo(view.graphics).then(function () {
            if (view.zoom === 7) view.zoom -= 1;
          });
        })
        .catch(function (err) {
          // A rejected view indicates a fatal error making it unable to display.
          // Use the errback function to handle when the view doesn't load properly
          console.error("MapView rejected:", err);
        });
    }

    function createGraphic(location, selected) {
      // First create a point geometry
      var point = {
        type: "point", // autocasts as new Point()
        latitude: location.latitude,
        longitude: location.longitude,
      };

      // Create a graphic and add the geometry and symbol to it
      var pointGraphic = new Graphic({
        geometry: point,
        symbol: selected ? selectMarkerSymbol : defaultMarkerSymbol,
        attributes: { text: location.text, value: location.value },
      });

      // Add the graphics to the view's graphics layer
      view.graphics.add(pointGraphic);
    }
    return () => view && view.destroy();

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    JSON.stringify(selectedLocation),
    /* eslint-enable react-hooks/exhaustive-deps */
    selectedLocation,
    geolocationPicker,
    onChange,
    setBasemapIndex,
  ]);

  return (
    <>
      <div
        ref={mapRef}
        style={{ padding: "0", margin: "0", height: "500px", width: "100%" }}
      />
    </>
  );
};

export default MapComponent;
