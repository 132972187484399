import { models } from "powerbi-client";
import "./DashboardPage.css";
import { PowerBIEmbed } from "powerbi-client-react";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./PowerBIReport.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { fetchFromAPI, fetchPowerBIReport } from "../../utils/hooks.js";
import Loader from "../Loader.js";
import { Typography } from "@mui/material";
import config from "../../config.js";

function DashboardPage() {
  const [isLoading, setLoading] = useState(true);
  const [fishReport, setFishReport] = useState(null);
  const [waterBodyReport, setWaterBodyReport] = useState(null);
  const [languageConfig, setLanguageConfig] = useState(null);
  const [areaReport, setAreaReport] = useState(null);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });
  const [reportValue, setReportValue] = useState("Fish");
  const [reportConfig, setReportConfig] = useState({});
  const [languageSelection, setLanguageSelection] = useState("en");
  const [bookmarkName, setBookmarkName] = useState("");

  const handleChange = (_, newValue) => {
    switch (newValue) {
      case "Fish":
        setReportConfig(fishReport);
        break;
      case "Water":
        setReportConfig(waterBodyReport);
        break;
      case "Area":
      default:
        setReportConfig(areaReport);
        break;
    }

    setReportValue(newValue);
    updateReportLanguage(newValue, languageSelection);
  };

  const handleLanguage = (event) => {
    const sel = event.target.value;

    setLanguageSelection(sel);
    updateReportLanguage(reportValue, sel);
  };

  const updateReportLanguage = (report, language) => {
    const languageOptions = languageConfig.find(
      (lang) => lang.language_code === language && lang.report === report
    );

    setBookmarkName(languageOptions.bookmark_guid);
  };

  useEffect(() => {
    Promise.all([
      fetchFromAPI("LanguageSelection"),
      fetchPowerBIReport(config.FISH_REPORT),
      fetchPowerBIReport(config.WATERBODY_REPORT),
      fetchPowerBIReport(config.AREA_REPORT),
    ])
      .then(([LanguageConfig, FishConfig, WaterBodyConfig, AreaConfig]) => {
        setLanguageConfig(LanguageConfig.result.LanguageSelection);
        setFishReport(FishConfig);
        setWaterBodyReport(WaterBodyConfig);
        setAreaReport(AreaConfig);
        setReportConfig(FishConfig);
        setLoading(false);
      })
      .catch((error) => console.log("Error", error));
  }, []);

  return (
    <div className="section collapsed">
      <div className="container">
        <div className="page-title">
          <Typography
            variant="h1"
            textAlign="center"
            sx={{
              fontSize: "3rem",
              fontWeight: "bold",
              paddingTop: "2rem",
              color: () => "#045070",
            }}
          >
            Ready to Fish
          </Typography>
          <Typography
            textAlign="center"
            sx={{
              fontSize: "1.2rem",
            }}
          >
            An improved angler experience powered by angling data.
          </Typography>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Stack
              direction="row"
              sx={{
                paddingTop: 5,
                paddingBottom: 5,
                alignItems: "end",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: "1.2rem",
                }}
              >
                Search by:
              </Typography>
              <FormControl>
                <InputLabel id="language-select">Language</InputLabel>
                <Select
                  labelId="language-select"
                  value={languageSelection}
                  label="Language"
                  onChange={handleLanguage}
                  sx={{
                    width: 250,
                  }}
                >
                  {languageConfig
                    .filter((l) => l.report === "Area")
                    .map((item, i) => (
                      <MenuItem key={i} value={item.language_code}>
                        {item.language_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
            <Box paddingBottom={2} sx={{ width: "100%" }}>
              <TabContext value={reportValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange} variant="fullWidth">
                    <Tab
                      label={
                        <Typography
                          sx={{
                            fontSize: "1.2rem",
                          }}
                        >
                          Fish
                        </Typography>
                      }
                      value="Fish"
                    />
                    <Tab
                      label={
                        <Typography
                          sx={{
                            fontSize: "1.2rem",
                          }}
                        >
                          Water Name
                        </Typography>
                      }
                      value="Water"
                    />
                    <Tab
                      label={
                        <Typography
                          sx={{
                            fontSize: "1.2rem",
                          }}
                        >
                          Area
                        </Typography>
                      }
                      value="Area"
                    />
                  </TabList>
                </Box>
                <PowerBIEmbed
                  key={bookmarkName}
                  embedConfig={{
                    type: "report", // Supported types: report, dashboard, tile, visual and qna
                    id: reportConfig.reportId,
                    embedUrl: reportConfig.embedUrl,
                    accessToken: reportConfig.embedToken,
                    tokenType: models.TokenType.Embed,
                    bookmark: {
                      name: bookmarkName,
                    },
                    settings: {
                      localeSettings: {
                        language: languageSelection, // Set language to Spanish
                      },
                      layoutType: isTabletOrMobile
                        ? models.LayoutType.MobilePortrait
                        : models.LayoutType.Master,
                      panes: {
                        filters: {
                          expanded: false,
                          visible: false,
                        },
                        pageNavigation: {
                          visible: false,
                        },
                      },
                    },
                  }}
                  eventHandlers={
                    new Map([
                      [
                        "loaded",
                        function (report) {
                          console.log("Report loaded", report);
                        },
                      ],
                      [
                        "rendered",
                        function () {
                          console.log("Report rendered");
                        },
                      ],
                      [
                        "error",
                        function (event) {
                          console.log("Error", event.detail);
                        },
                      ],
                    ])
                  }
                  cssClassName={
                    isTabletOrMobile
                      ? "report-style-class-mobile"
                      : "report-style-class"
                  }
                />
              </TabContext>
            </Box>
          </>
        )}
      </div>
    </div>
  );
}

export default DashboardPage;
